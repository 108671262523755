import * as React from 'react';
import { mutate } from 'swr';
import { useUserContext } from '../../auth/authContext';
import { isInRole } from '../../functions/auth.functions';
import { addDate, deepEqual } from '../../functions/common.functions';
import { useApiContext } from '../../store/ApiContext';
import { findCohortSummaryUrl, useFetchCurrentCohortSummary } from '../../store/cohort/CohortSummaryFetcher';
import { CohortSummary } from '../../store/cohort/CohortSummaryModel';
import { KnownSettings } from '../../store/SettingsModel';
import { IFormError } from "../../functions/form.contracts";
import { CohortItemStatusType, defaultCohortItemStatusRoles } from '../../store/cohort/CohortItemStatus';
import useSessionStorage from '../../functions/storage.hooks';

export interface ISortSelection {
   field: string;
   order: 'ascend' | 'descend' | null;
}

interface ISummaryFilterAndSortOptions {
   cohortTypeId: number;
   partnerFilter: string;
   diseaseOrBrandFilter: string;
   assistanceProgramFilter: string;
   assistanceServiceFilter: string;
   columnSortSelection: ISortSelection;
}

interface ICohortContextProps {
   cohortId: number;
   setCohortId: (cohortId: number) => void;
   cardTitle: string;
   setCardTitle: (title: string) => void;
   cardSubText: string;
   setCardSubText: (subText: string) => void;
   selectedProgramSummaryDetail: ISelectedProgramSummaryDetail,
   setSelectedProgramSummaryDetail: (detail: ISelectedProgramSummaryDetail) => void;
   selectedCohortItemStatusIds: number[];
   setSelectedCohortItemStatusIds: (ids: number[]) => void;

   currentCohort: CohortSummary;
   summaryFilterAndSortOptions: ISummaryFilterAndSortOptions;
   setSummaryFilterAndSortOptions: (options: ISummaryFilterAndSortOptions) => void;
   cohortSummaryFromDate: Date;
   setCohortSummaryFromDate: (fromDate: Date) => void;

   formErrors: IFormError[],
   setFormErrors: (errors: IFormError[]) => void;
}

export interface ISelectedProgramSummaryDetail {
   assistanceProgramId: number;
   assistanceServiceId?: number;
   diseaseOrBrandId?: number;
}

export const CohortContext = React.createContext<ICohortContextProps>({} as ICohortContextProps);
export const useCohortContext = () => React.useContext(CohortContext);

interface ICohortContextProviderProps {
   children: React.ReactNode;
}

enum SessionKeysEnum {
   SELECTEDPROGRAMSUMMARYDETAIL = 'cohort-selectedProgramSummaryDetail',
   SELECTEDCOHORTITEMSTATUSIDS = 'cohort-selectedCohortItemStatusIds',
   AllSummaryFilterAndSortOptions = 'cohort-summaryFilterAndSortOptions',
}

const cohortSummarySeedDate = addDate(new Date(), -180);

export const CohortContextProvider: React.FC<ICohortContextProviderProps> = (props) => {
   const [cohortSummaryFromDateParam, setCohortSummaryFromDateParam] = React.useState<Date>(cohortSummarySeedDate);
   const [cohortId, setCohortId] = React.useState<number>(undefined);
   const { userRoles } = useUserContext();
   const { httpPost } = useApiContext();
   const { currentCohort } = useFetchCurrentCohortSummary(httpPost, cohortSummaryFromDateParam, cohortId);

   const [cardTitle, setCardTitle] = React.useState<string>(undefined);
   const [cardSubText, setCardSubText] = React.useState<string>(undefined);
   const [selectedCohortItemStatusIds, setSelectedCohortItemStatusIds] = useSessionStorage<number[]>(SessionKeysEnum.SELECTEDCOHORTITEMSTATUSIDS, []);
   const [selectedProgramSummaryDetail, setSelectedProgramSummaryDetail] = useSessionStorage<ISelectedProgramSummaryDetail>(SessionKeysEnum.SELECTEDPROGRAMSUMMARYDETAIL, undefined);
   const [allSummaryFilterAndSortOptions, setAllSummaryFilterAndSortOptions] = useSessionStorage<ISummaryFilterAndSortOptions[]>(SessionKeysEnum.AllSummaryFilterAndSortOptions, []);

   const [formErrors, setFormErrors] = React.useState<IFormError[]>([]);

   React.useEffect(() => {
      setSelectedCohortItemStatusIds(defaultCohortItemStatusRoles(userRoles));
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   //method to map options to a specific cohorttype in an effort to allow different settings per type.
   const setSummaryFilterAndSortOptions = (options: ISummaryFilterAndSortOptions) => {
      const idx = allSummaryFilterAndSortOptions?.findIndex(y => y.cohortTypeId === options.cohortTypeId)

      const newOptions = { ...options, cohortTypeId: currentCohort.cohortTypeId };
      const objCopy = [...allSummaryFilterAndSortOptions];

      if (idx > -1) {
         objCopy[idx] = newOptions;
      } else {
         objCopy.push(newOptions);
      }
      setAllSummaryFilterAndSortOptions(objCopy);
   }

   //abstraction to keep up the interface without forcing consumers to understand all things
   const summaryFilterAndSortOptions = allSummaryFilterAndSortOptions?.find(y => y.cohortTypeId === currentCohort?.cohortTypeId)

   const setCohortSummaryFromDate = (fromDate: Date) => {
      setCohortSummaryFromDateParam(fromDate);
      mutate(findCohortSummaryUrl);
   }

   return (
      <CohortContext.Provider
         value={{
            cohortId,
            setCohortId,
            cardTitle,
            setCardTitle,
            cardSubText,
            setCardSubText,
            setSelectedProgramSummaryDetail,
            selectedProgramSummaryDetail,
            cohortSummaryFromDate: cohortSummaryFromDateParam,
            setCohortSummaryFromDate,
            currentCohort,
            summaryFilterAndSortOptions,
            setSummaryFilterAndSortOptions,
            selectedCohortItemStatusIds,
            setSelectedCohortItemStatusIds,
            setFormErrors,
            formErrors
         }}
      >
         {props.children}
      </CohortContext.Provider>
   );
};
