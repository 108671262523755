import { Col, Row } from 'antd';
import type { ColumnsType, TableProps } from 'antd/lib/table';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useUserContext } from '../../../auth/authContext';
import { isInRole } from '../../../functions/auth.functions';
import { stringComparer } from '../../../functions/comparer.functions';
import { useApiContext } from '../../../store/ApiContext';
import { KnownSettings } from '../../../store/SettingsModel';
import { cohortItemStatuses, defaultCohortItemStatusRoles } from '../../../store/cohort/CohortItemStatus';
import { programSummaryBaseUrl, useFetchProgramSummary } from '../../../store/cohort/ProgramSummaryFetcher';
import { ProgramSummary } from '../../../store/cohort/ProgramSummaryModel';
import { serviceCategoryBaseUrl, useCoPayServiceCategoryOptions } from '../../../store/program/ServiceCategoryFetcher';
import ApiErrorDisplay from '../../ApiErrorDisplay';
import Spinner from '../../Spinner';
import '../../grid/Grid.css';
import SearchResultsTable from '../../shared/AntComponents/Table/SearchResultsTable';
import { BasicCheckboxListField, BasicInputField } from '../../shared/BasicInputLibrary';
import { ISortSelection, useCohortContext } from '../CohortContext';
import HighlightSearchText from '../../HighlightSearchText';

const errorKeys = { keys: [programSummaryBaseUrl, serviceCategoryBaseUrl] };

const ProgramSummaryList: React.FC = () => {
   const { cohortId: cohortIdRaw } = useParams();
   const { userRoles } = useUserContext();

   const { httpGet } = useApiContext();
   const [serviceCategoriesFilter, setServiceCategoriesFilter] = React.useState<number[]>([]);

   const [currentPage, setCurrentPage] = React.useState(1);
   const [currentPageSize, setCurrentPageSize] = React.useState(25);   
   const [filteredItems, setFilteredItems] = React.useState<ProgramSummary[]>([]);

   const { serviceCategoryOptions } = useCoPayServiceCategoryOptions(httpGet);
   const {
      cohortId, setCohortId, setCardTitle, setCardSubText,
      currentCohort, setSelectedProgramSummaryDetail,
      selectedCohortItemStatusIds, setSelectedCohortItemStatusIds,
      summaryFilterAndSortOptions, setSummaryFilterAndSortOptions
   } = useCohortContext();

   const handleOnChange: TableProps<ProgramSummary>['onChange'] = (pagination, filters, sorter, extra) => {
      if (sorter) {
         setSummaryFilterAndSortOptions({
            ...summaryFilterAndSortOptions,
            columnSortSelection: sorter as unknown as ISortSelection
         });
      }
      setCurrentPage(pagination?.current);
   }

   React.useEffect(() => {
      //NOTE: setting context cohortid here becuase the context is initialized 
      // above react router - useParams will not fire outside of the Route render
      const setDefaultCohortItemStatusIdsEffect = () => {
         if (!selectedCohortItemStatusIds) {
            setSelectedCohortItemStatusIds(defaultCohortItemStatusRoles(userRoles));
         }
      }
      setDefaultCohortItemStatusIdsEffect();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   React.useEffect(() => {
      //NOTE: setting context cohortid here becuase the context is initialized 
      // above react router - useParams will not fire outside of the Route render
      const setCohortIdEffect = () => {
         if (cohortIdRaw) {
            setCohortId(Number(cohortIdRaw));
         }
      }
      setCohortIdEffect();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [cohortIdRaw]);
   //const { currentCohort } = useFetchCurrentCohortSummary(httpPost, cohortSummaryFromDate, cohortId);
   const { results, error, isLoading } = useFetchProgramSummary(httpGet, cohortId);

   React.useEffect(() => {
      const changeCohort = (): void => {
         if (currentCohort && cohortId && currentCohort.cohortId === cohortId) {
            const isAdmin: boolean = isInRole(userRoles, [KnownSettings.CohortAdmin]);
            setCardTitle(currentCohort.name);
            if (isAdmin) {
               setCardSubText(`${currentCohort.recommendedChanges} Remaining of ${currentCohort.acceptedChanges + currentCohort.rejectedChanges + currentCohort.recommendedChanges} Changes - ${currentCohort.totalItems} Items`)
            } else {
               setCardSubText(`Completed ${currentCohort.totalItems - currentCohort.newItems} of ${currentCohort.totalItems} Items`)
            }
         }
      }
      changeCohort();

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [cohortId, currentCohort]);


   React.useEffect(() => {
      const _effect = () => {
         if (serviceCategoryOptions) {
            setServiceCategoriesFilter(serviceCategoryOptions.map(y => y.value as number));
         }
      }
      _effect();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [serviceCategoryOptions]);

   React.useEffect(() => {
      createFilteredItems();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [results,
      selectedCohortItemStatusIds,
      serviceCategoriesFilter,
      summaryFilterAndSortOptions
   ]);

   const createFilteredItems = () => {
      if (!results || results.length <= 0) return;

      let newList = results.filter(model => {
         return filterPartner(model) &&
            filterAssistanceProgram(model) &&
            filterAssistanceService(model) &&
            filterDiseaseOrBrand(model) &&
            filterCohortItemStatusIds(model) &&
            filterServiceCategories(model);
      }) ?? [];

      setFilteredItems(newList);
   }

   const filterPartner = (p: ProgramSummary): boolean => {
      if (!summaryFilterAndSortOptions?.partnerFilter) return true;
      if (p.partner?.toLowerCase().indexOf(summaryFilterAndSortOptions?.partnerFilter.toLowerCase()) > -1 ||
         String(p.partnerId).toLowerCase().indexOf(summaryFilterAndSortOptions?.partnerFilter.toLowerCase()) > -1) {
         return true;
      }
      return false;
   }

   const filterAssistanceProgram = (p: ProgramSummary): boolean => {
      if (!summaryFilterAndSortOptions?.assistanceProgramFilter) return true;
      if (p.programName?.toLowerCase().indexOf(summaryFilterAndSortOptions?.assistanceProgramFilter.toLowerCase()) > -1 ||
         String(p.assistanceProgramId).toLowerCase().indexOf(summaryFilterAndSortOptions?.assistanceProgramFilter.toLowerCase()) > -1) {
         return true;
      }
      return false;
   }

   const filterAssistanceService = (p: ProgramSummary): boolean => {
      if (!summaryFilterAndSortOptions?.assistanceServiceFilter) return true;
      if (p.assistanceServiceName?.toLowerCase().indexOf(summaryFilterAndSortOptions?.assistanceServiceFilter.toLowerCase()) > -1 ||
         String(p.assistanceServiceId).toLowerCase().indexOf(summaryFilterAndSortOptions?.assistanceServiceFilter.toLowerCase()) > -1) {
         return true;
      }
      return false;
   }

   const filterDiseaseOrBrand = (p: ProgramSummary): boolean => {
      if (!summaryFilterAndSortOptions?.diseaseOrBrandFilter) return true;
      if (p.diseaseOrBrand?.toLowerCase().indexOf(summaryFilterAndSortOptions?.diseaseOrBrandFilter.toLowerCase()) > -1 ||
         String(p.diseaseOrBrandId).toLowerCase().indexOf(summaryFilterAndSortOptions?.diseaseOrBrandFilter.toLowerCase()) > -1) {
         return true;
      }
      return false;
   }

   const filterCohortItemStatusIds = (p: ProgramSummary): boolean => {
      if (!selectedCohortItemStatusIds || selectedCohortItemStatusIds.length === 0) return true;
      return p.cohortItemStatusIds?.some(y => selectedCohortItemStatusIds.includes(y));
   }

   const filterServiceCategories = (p: ProgramSummary): boolean => {
      if (!serviceCategoriesFilter || serviceCategoriesFilter.length === 0) return true;
      return p.serviceCategoryIds?.some(y => serviceCategoriesFilter.includes(y));
   }

   const tableColumns: ColumnsType<ProgramSummary> = [
      {
         title: 'Foundation / Manufacturer',
         dataIndex: 'partner',
         key: 'partner',
         sorter: (a, b, sortOrder) => stringComparer(a.partner, b.partner),
         sortDirections: ['ascend', 'descend', 'ascend'],
         defaultSortOrder: summaryFilterAndSortOptions?.columnSortSelection?.field === 'partner' ? summaryFilterAndSortOptions.columnSortSelection.order : null,
         render: (text, record) => <HighlightSearchText searchString={summaryFilterAndSortOptions?.partnerFilter} targetString={record.partner} />
      },
      {
         title: 'Assistance Program',
         dataIndex: 'programName',
         key: 'programName',
         sorter: (a, b, sortOrder) => stringComparer(a.programName, b.programName),
         sortDirections: ['ascend', 'descend', 'ascend'],
         defaultSortOrder: summaryFilterAndSortOptions?.columnSortSelection?.field === 'programName' ? summaryFilterAndSortOptions.columnSortSelection.order : null,
         render: (text, record) => {
            return (<Link to={{
               pathname: `/cohort/programsummarydetail/${cohortId}`,
            }}
               title={`[${record.assistanceProgramId}] ${record.programName}`}
               onClick={() => setSelectedProgramSummaryDetail({ assistanceProgramId: record.assistanceProgramId })}
            >
               <HighlightSearchText searchString={summaryFilterAndSortOptions?.assistanceProgramFilter} targetString={record.programName} /> &#8599;
            </Link>)
         }
      },
      {
         title: 'Assistance Service',
         dataIndex: 'assistanceServiceName',
         key: 'assistanceServiceName',
         sorter: (a, b, sortOrder) => stringComparer(a.assistanceServiceName, b.assistanceServiceName),
         sortDirections: ['ascend', 'descend', 'ascend'],
         defaultSortOrder: summaryFilterAndSortOptions?.columnSortSelection?.field === 'assistanceServiceName' ? summaryFilterAndSortOptions.columnSortSelection.order : null,
         render: (text, record) => {
            return (<div className='column'>
               <Link to={{
                  pathname: `/cohort/programsummarydetail/${cohortId}`,
               }}
                  title={`[${record.assistanceServiceId}] ${record.assistanceServiceName}`}
                  onClick={() => setSelectedProgramSummaryDetail({ assistanceProgramId: record.assistanceProgramId, assistanceServiceId: record.assistanceServiceId })}
               >
                  <HighlightSearchText searchString={summaryFilterAndSortOptions?.assistanceServiceFilter} targetString={record.assistanceServiceName} /> &#8599;
               </Link>
            </div>)
         }
      },
      {
         title: 'Disease Type / Brand',
         dataIndex: 'diseaseOrBrand',
         key: 'diseaseOrBrand',
         fixed: 'right',
         sorter: (a, b, sortOrder) => stringComparer(a.diseaseOrBrand, b.diseaseOrBrand),
         sortDirections: ['ascend', 'descend', 'ascend'],
         defaultSortOrder: summaryFilterAndSortOptions?.columnSortSelection?.field === 'diseaseOrBrand' ? summaryFilterAndSortOptions.columnSortSelection.order : null,
         render: (text, record) => {
            return (<Link to={{
               pathname: `/cohort/programsummarydetail/${cohortId}`,
            }}
               title={`[${record.diseaseOrBrandId}] ${record.diseaseOrBrand}`}
               onClick={() => setSelectedProgramSummaryDetail({ assistanceProgramId: record.assistanceProgramId, assistanceServiceId: record.assistanceServiceId, diseaseOrBrandId: record.diseaseOrBrandId })}
            >
               <HighlightSearchText searchString={summaryFilterAndSortOptions?.diseaseOrBrandFilter} targetString={record.diseaseOrBrand} /> &#8599;
            </Link>)
         }
      },
   ];

   if (!currentCohort) {
      return <Spinner message='loading selected cohort...' />;
   }

   const html = (
      <>
         <div style={{ padding: 10 }} className='cohortProgramSummary'>
            <Row gutter={[8, 0]}>
               <Col span={20}>
                  <BasicCheckboxListField
                     label='Record State'
                     name="cohortItemStatusIds"
                     value={selectedCohortItemStatusIds}
                     options={cohortItemStatuses}
                     onChange={(e) => setSelectedCohortItemStatusIds(e as number[])}
                     containerStyle={{ marginBottom: 2 }}
                  />
               </Col>
               <Col span={4}>
                  <BasicCheckboxListField
                     label='Service Categories'
                     name="endDate"
                     value={serviceCategoriesFilter}
                     options={serviceCategoryOptions}
                     onChange={(e) => setServiceCategoriesFilter(e as number[])}
                     containerStyle={{ marginBottom: 2 }}
                  />
               </Col>
            </Row>
            <Row gutter={[16, 16]}>
               <Col span={6}>
                  <BasicInputField
                     placeholder='Enter Id or Foundation / Manufacturer'
                     label='Foundation / Manufacturer'
                     name="partner"
                     value={summaryFilterAndSortOptions?.partnerFilter ?? ''}
                     onChange={(e) => setSummaryFilterAndSortOptions({ ...summaryFilterAndSortOptions, partnerFilter: e as string })}
                  />
               </Col>
               <Col span={6}>
                  <BasicInputField
                     placeholder='Enter Id or Program Name'
                     label='Assistance Program'
                     name="partner"
                     value={summaryFilterAndSortOptions?.assistanceProgramFilter ?? ''}
                     onChange={(e) => setSummaryFilterAndSortOptions({ ...summaryFilterAndSortOptions, assistanceProgramFilter: e as string })}
                  />
               </Col>
               <Col span={6}>
                  <BasicInputField
                     placeholder='Enter Id or Service Name'
                     label='Assistance Service'
                     name="partner"
                     value={summaryFilterAndSortOptions?.assistanceServiceFilter ?? ''}
                     onChange={(e) => setSummaryFilterAndSortOptions({ ...summaryFilterAndSortOptions, assistanceServiceFilter: e as string })}
                  />
               </Col>
               <Col span={6}>
                  <BasicInputField
                     placeholder='Enter Id or Brand Name'
                     label='Disease Type / Brand'
                     name="partner"
                     value={summaryFilterAndSortOptions?.diseaseOrBrandFilter ?? ''}
                     onChange={(e) => setSummaryFilterAndSortOptions({ ...summaryFilterAndSortOptions, diseaseOrBrandFilter: e as string })}
                  />
               </Col>
            </Row>
         </div>
         
         <ApiErrorDisplay
            title='Error loading data - try refreshing the browser or add additional search criteria.'
            keys={errorKeys.keys} />

         <SearchResultsTable
            //rowkey={(record: ProgramSummary) => `${record.partnerId}_${record.assistanceProgramId}_${record.assistanceServiceId}_${record.diseaseOrBrandId}`}
            rowkey='rowKey'
            onChange={handleOnChange}
            columns={tableColumns}
            data={filteredItems}
            currentPage={currentPage}
            currentPageSize={currentPageSize}
            setCurrentPageSize={setCurrentPageSize}
            titleText=''
            fixedHeader={true}
            scrollY='calc(100vh - 440px)'
            loading={isLoading}
         />
      </>
   );

   return html;
}

export default ProgramSummaryList