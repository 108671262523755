import { IOptionItem } from '../../functions/option.functions';

export interface AssistanceProgram {
   id: number;
   practiceId: number;
   programName: string;
   programDescription: string
   programType: ProgramType;
   serviceSelectionMessage: string;
   logoUrl: string;
   isDemo: boolean;
   canSelectAllServices: boolean;
   connectivityType: number;
   interfaceId?: number;
   faxNumber: string;
   singleServicePerApplication?: boolean;
   //doesn't really belong here
   foundationId?: number;
   programTypeDisplay: string;
   docuSignPurgeDays: number;
}

export enum ProgramType {
   Manufacturer = 0,
   Foundation = 1
}

export enum ConnectivityType {
   Manual = 0,
   OutboundDigitalApplication = 1,
   DigitalEnrollment = 2,
   DigitalEnrollment15 = 3,
   RPA = 4
}

export const programTypeOptions: IOptionItem[] = [
   {
      key: Number(ProgramType.Manufacturer),
      value: ProgramType.Manufacturer,
      label: 'Manufacturer'
   } as IOptionItem,
   {
      key: Number(ProgramType.Foundation),
      value: ProgramType.Foundation,
      label: 'Foundation'
   } as IOptionItem
]