import * as React from 'react';
import ContentCard from '../layouts/ContentCard';
import { verifyAllUsers, verifyAllUsersUrl } from '../store/auth/Auth0MaintenanceFetcher';
import CustomIcon, { CustomIconType } from './shared/AntComponents/CustomIcon';
import { NumberedTitle, TitleError } from './shared/AntComponents/Typography/Title';
import { ActionButton } from './shared/Buttons';
import { useApiContext } from '../store/ApiContext';
import ApiErrorDisplay from './ApiErrorDisplay';

const _keys: string[] = [verifyAllUsersUrl];

const Maintenance: React.FC = () => {

   const { httpPatch } = useApiContext();

   const [isVerifying, setIsVerifying] = React.useState<boolean>(false);
   const [errorMessage, setErrorMessage] = React.useState<string>();
   const [message, setMessage] = React.useState<string>();

   const callApiToVerify = async  () => {
      setIsVerifying(true); 
      setMessage(undefined);
      setErrorMessage(undefined);

      await verifyAllUsers(httpPatch)
         .then((res) => {
            setMessage(`Set ${res ?? -1} users to verified`);
         })
         .catch(err => {
            console.error('Error Verifying', err)
            setErrorMessage(`Error Verifying - check console`);
         })
         .finally(() => {
            setIsVerifying(false); 
         })
   }


    const html = (
       <ContentCard title="Maintenance">

          {errorMessage && <TitleError text={errorMessage} />}
          {message && <NumberedTitle text={message} level={3} />}

          <ApiErrorDisplay
             title='Error saving Manufacturer'
             keys={_keys}
          />
          <ActionButton
             buttonText='Click to Verify Users'
             filled={true}
             icon={<CustomIcon type={CustomIconType.SendOutlined} />}
             onClick={callApiToVerify}
             disabled={isVerifying}
             loading={isVerifying}
          />
       </ContentCard>
    )
    return html;
}
export default Maintenance;
