import { IOptionItem } from "../../functions/option.functions";
import { ApplicationStatuses } from "./EnrollmentStatusConstants";

export interface EnrollmentStatusSearchResult {
   applicationId: number,
   assistanceProgramId: number,
   programName: string,
   patientId: string,
   practiceId: number,
   brandId: number,
   brandName: string,
   practiceName: string,
   submittedBy: number,
   submittedOn?: Date,
   submittedFirstName: string,
   submittedLastName: string,
   patientName: string,
   assistanceServiceNames: string,
   deleted: boolean,
}

export interface EnrollmentStatus {
   applicationId: number,
   applicationStatus: number,
   assistanceProgramId: number,
   programName: string,
   patientId: string,
   practiceId: number,
   brandId: number,
   practiceName: string,
   submittedBy: number,
   submittedOn?: Date,
   submittedFirstName: string,
   submittedLastName: string,
   createdOn?: Date,
   createdFirstName: string,
   createdLastName: string,
   patientName: string,
   followUp: Date,
   deleted: boolean,
   deletedByFirstName: string,
   deletedByLastName: string,
   deletedOn: Date,
   applicationServices: ApplicationService[],
};

export interface EnrollmentStatusViewModel extends EnrollmentStatus {
   brandName?: string;
}

export interface ApplicationService {
   applicationId: number,
   applicationServiceId: number,
   assistanceServiceId: number,
   applicationServiceType: number,
   serviceStatus: number,
   assistanceServiceName: string,
   serviceCategoryId: number,
   serviceCategory: string,
   enrollments: Enrollment[],
};

export interface AssistanceServiceEnrollment {
   assistanceServiceId: number,
   assistanceServiceName: string,
   assistanceProgramId: number,
   programName: string,
   assistanceType: number
   serviceCategoryId: number,
   serviceCategory: string,
   approvedAwardAmount?: number,
   benefitPeriodTypeId?: number,
   benefitPeriodStartMonth?: number,
   benefitPeriodStartDay?: number,
   enrollments: Enrollment[],
};

export interface Enrollment {
   enrollmentId: number,
   status?: number,
   applicationServiceId: number,
   startDate?: Date,
   endDate?: Date,
   trackingId: string,
   approvedAmount?: number,
   backDate?: number,
   timelyFiling?: number,
   guaranteedAmount?: number,
   patientCopay?: number,
   PatientCopayDose1?: number
   benefitPeriodTypeId: number,
   benefitPeriodStartMonth?: number,
   benefitPeriodStartDay?: number,
   memberId?: number
   reenrollmentId?: number
};

export interface EnrollmentUpload {
   applicationId: number,
   file: File,
   enrollment: Enrollment,
   fileName: string,
   fileDescription: string,
}

export interface BetterEnrollmentUpload {
   enrollmentId: number,
   file: File,
   fileDescription: string
}

export interface EnrollmentStatusSearchRequest {
   page: number;
   pageSize: number;
   sortField: string;
   sortDirection: string;
   trackingId?: string;
   serviceStatusIds?: number[];
   applicationId?: string;
   assistanceProgramIds?: number[];
   patientName?: string;
   patientIds?: string[];
   practiceId?: string;
   practiceName?: string;
   submittedBy?: number[];
   excludePracticeIds?: boolean;
}

export enum ColumnName {
   ApplicationId,
   ApplicationStatus,
   ProgramName,
   ApplicationServices,
   PatientId,
   PracticeId,
   PracticeName,
   SubmittedOn,
   SubmittedBy,
   Deleted
};

export const formatApplicationProgramName = (assistanceProgramId: number, assistanceProgramName: string) => {
   return `[${assistanceProgramId}] ${assistanceProgramName}`
}

export const formatDeletedName = (app: EnrollmentStatus) => {
   if (!app || !app.deletedOn || (!app.deletedByFirstName && !app.deletedByLastName)) { return ''; }
   return (app.deletedByFirstName + " " + app.deletedByLastName) ?? '';
}

export const formatDeleted = (app: EnrollmentStatus) => {
   if (app?.deleted) { return 'Yes'; }
   return '';
}

export const formatSubmittedName = (app: EnrollmentStatus) => {
   if (!app || !app.submittedOn || (!app.submittedFirstName && !app.submittedLastName)) { return ''; }
   return app.submittedFirstName + " " + app.submittedLastName;
}

export const formatCreatedName = (app: EnrollmentStatus) => {
   if (!app || !app.createdOn || (!app.createdFirstName && !app.createdLastName)) { return ''; }
   return app.createdFirstName + " " + app.createdLastName;
}

export const formatApplicationStatus = (app: EnrollmentStatus) => {
   const status = ApplicationStatuses.find(s => s.key === app.applicationStatus);
   return status === undefined ? '' : status.text;
}

export const formatServiceEnrollmentIds = (service: ApplicationService) => {
   return service.enrollments.map(e => e.enrollmentId === -1 ? "New" : e.enrollmentId).sort().join(', ') ?? '';
}