import * as React from 'react';
import Dialog from '../Dialog';
import { SaveButton, CancelButton } from '../shared/Buttons';
import Spinner from '../Spinner';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Foundation } from '../../store/program/FoundationModel';
import { useFetchFoundations, saveFoundation, foundationBaseUrl, useFoundationById } from '../../store/program/FoundationFetcher';
import { usePracticeOptions } from '../../store/practice/PracticeFetcher'
import { Form } from 'antd';
import { InputField, DropdownField, TextAreaField, CheckboxField } from '../shared/InputLibrary';
import { TitleError } from '../shared/AntComponents/Typography/Title';
import { useApiContext } from '../../store/ApiContext';
import ApiErrorDisplay from '../ApiErrorDisplay';
import { useErrorContext } from '../../store/ErrorContext';

interface IProps {
   isOpen: boolean;
   closeEditor: () => void;
   id?: number;
};

const dialogContentStyle = {
   height: '580px',
   maxHeight: '95vh',
}

const yupFoundationSchema = yup.object({
   id: yup.number().notRequired(),
   foundationName: yup.string()
      .required('Foundation Name is required')
      .max(128, 'Max length is 128 characters'),
   foundationDescription: yup.string()
      .max(1024, 'Max length is 1024 characters')
      .required('Foundation Description is required'),
   foundationUrl: yup.string()
      .url()
      .max(256, 'Max length is 256 characters')
      .required(),
   logoUrl: yup.string()
      .url()
      .max(256, 'Max length is 256 characters')
      .notRequired(),
   backgroundUrl: yup.string()
      .url()
      .max(256, 'Max length is 256 characters')
      .notRequired(),
   practiceId: yup.number()
      .nullable()
      .transform((_, val) => val === '' ? null : Number(val)),

   scrapingStatus: yup.string().notRequired(),
   hasWaitList: yup.boolean().required(),
});

interface IFoundationYup extends yup.Asserts<typeof yupFoundationSchema> { }

const __formId = "frmFoundation";

const _keysLike: string[] = [foundationBaseUrl];


export const FoundationEditor: React.FC<IProps> = (props) => {
   const { closeEditor, isOpen, id } = props;
   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const { practiceOptions } = usePracticeOptions(httpGet, true);
   const { foundations } = useFetchFoundations(httpGet);

   const {
      control,
      handleSubmit,
      formState: { errors },
      reset
   } = useForm<IFoundationYup>({
      resolver: yupResolver<yup.AnyObject>(yupFoundationSchema),
      shouldFocusError: true,
   });

   React.useEffect(() => {
      let resetValue = { hasWaitList: false };
      if (id && foundations?.length > 0) {
         resetValue = foundations.find(y => y.id === id);
      }
      reset(resetValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [foundations, id]);

   const onSubmit = (data: IFoundationYup): void => {
      //console.log('--------------Editor-------------------------')
      //console.log(errors)

      //ts hoops to convert data with unknown props into assistance programs
      const item = data as unknown as Foundation;
      setIsFormSaving(true);
      saveFoundation(httpPost, item)
         .then(handleClose)
         .catch(err => console.error('Error Saving Foundation', err))
         .finally(() => {
            setIsFormSaving(false);
         });
   }

   const handleClose = () => {
      setIsFormSaving(false);
      removeErrors({ keysLike: _keysLike });
      closeEditor();
   }

   if (isFormSaving) {
      return <div><Spinner message='Saving Foundation...' /></div>
   }

   //console.log('--------------FoundationEditor-------------------------')
   //console.log(errors)


   const html = (
      <Dialog
         scrollingContent={true}
         title={'Foundation Editor'}
         open={isOpen}
         size="large"
         actionButtons={
            <>
               <CancelButton onClick={() => {
                  handleClose();
               }} />
               {/*<ActionButton buttonText='Delete Foundation'
                  onClick={() => setIsDeleteDialogOpen(true)}
                  disabled={!itemToEdit || !itemToEdit?.id || isDeleteDialogOpen}
                  loading={isDeleteDialogOpen} />*/}
               <SaveButton
                  formId={__formId}
                  onClick={() => null}
                  disabled={Object.keys(errors).length > 0}
                  loading={isFormSaving} />
            </>
         }>
         <div style={dialogContentStyle}>

            {errors && Object.keys(errors).length > 0 &&
               <>
                  <TitleError text='Please correct the errors below' />
                  {console.log('--------------Form Errors: -------------------------')}
                  {console.log(errors)}
               </>
            }

            <ApiErrorDisplay
               title='Error saving Foundation'
               keysLike={_keysLike}
            />

            <Form id={__formId} onFinish={handleSubmit(onSubmit)}>
               <InputField
                  control={control}
                  name='foundationName'
                  label='Foundation Name'
                  type='text'
                  error={errors?.foundationName}
                  required={true} />
               <TextAreaField
                  control={control}
                  name='foundationDescription'
                  label='Foundation Description'
                  error={errors?.foundationDescription}
                  required={true}
               />
               <InputField
                  control={control}
                  name='foundationUrl'
                  label='Foundation Url'
                  type='text'
                  error={errors?.foundationUrl}
                  required={true} />
               <InputField
                  control={control}
                  name='logoUrl'
                  label='Logo Url'
                  type='text'
                  error={errors?.logoUrl}
                  required={false} />
               <InputField
                  control={control}
                  name='backgroundUrl'
                  label='Background Url'
                  type='text'
                  error={errors?.backgroundUrl}
                  required={false} />
               <DropdownField
                  control={control}
                  name='practiceId'
                  label='Practice'
                  error={errors?.practiceId}
                  multiple={false}
                  options={practiceOptions}
                  required={true}
                  search={true}
                  clearable={false} />
               <InputField
                  control={control}
                  name='scrapingStatus'
                  label='Scraping Status (Not Editable)'
                  type='text'
                  error={errors?.scrapingStatus}
                  required={false}
                  disabled={true} />
               <CheckboxField
                  control={control}
                  name='hasWaitList'
                  label='Has Wait List'
                  error={errors?.hasWaitList}
                  toggle={true}
                  required={false} />
            </Form>
         </div>

         {/* 
         <Dialog
            title='CONFIRM DELETE'
            open={isDeleteDialogOpen}
            style={{ maxWidth: '550px' }}
            actionButtons={
               <>
                  <EndButton icon='ban'
                     buttonText='Cancel'
                     onClick={() => setIsDeleteDialogOpen(false)} />
                  <ActionButton
                     isPrimary={true}
                     buttonText='Delete Foundation'
                     disabled={isFormSaving}
                     onClick={() => handleDeleteClick()} />
               </>
            }
         >
            {`Are you sure you want to Delete the Foundation (${itemToEdit?.id}) ${itemToEdit?.name || ''}?`}
         </Dialog>
         */}
      </Dialog>
   )
   return html;
}

export default FoundationEditor;