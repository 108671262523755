import { mutate } from 'swr';
import { TPost } from '../../functions/httpClient';
import { UpdateRecipientsESignatureModel } from './DocuSignModels';
import { mutateDocuSignRequestSearch } from './DocuSignRequestFetcher';
 

export const docuSignBaseUrl = `api/DocuSign`;
const resendEnvelopeUrl = (applicationId: number) => `${docuSignBaseUrl}/ResendEnvelope/${applicationId}`;
const voidEnvelopeUrl = (applicationId: number) => `${docuSignBaseUrl}/VoidEnvelope/${applicationId}`;
const updateAndResendRequestUrl = `${docuSignBaseUrl}/UpdateAndResendRequest`

export const resendEnvelope = async (httpPost: TPost, applicationId: number) => {
   const result = await httpPost(resendEnvelopeUrl(applicationId))
      .then((r) => {
         mutateDocuSignRequestSearch();
         return r;
      });
   return result;
}

export const voidEnvelope = async (httpPost: TPost, applicationId: number) => {
   const result = await httpPost(voidEnvelopeUrl(applicationId))
      .then((r) => {
         mutateDocuSignRequestSearch();
      return r;
   });
   return result;
}

export const updateAndResendRequest = async (httpPost: TPost, model: UpdateRecipientsESignatureModel) => {
   const result = await httpPost(updateAndResendRequestUrl, model)
      .then((r) => {
         mutateDocuSignRequestSearch();
         return r;
      });
   return result;
}