import { ColumnsType, TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { formatDateTimeString } from '../../functions/format.functions';
import {
   enrollmentStatusSearchUrl, useAvailableProgramOptions,
   useEnrollmentStatusSearch, useFetchEnrollmentStatusPatientIds
} from '../../store/content-tool/EnrollmentStatusFetcher';
import {
   EnrollmentStatusSearchRequest,
   EnrollmentStatusSearchResult,
   EnrollmentStatusViewModel,
   EnrollmentUpload,
   formatApplicationProgramName
} from '../../store/content-tool/EnrollmentStatusModel';
import { EnrollmentStatusServiceStatusOptions } from '../../store/content-tool/EnrollmentStatusConstants';
import HighlightSearchText from '../HighlightSearchText';
import CustomIcon, { CustomIconType } from '../shared/AntComponents/CustomIcon';
import { BasicDropdownField, BasicInputField } from '../shared/BasicInputLibrary';
import { ActionButton, EditButton } from '../shared/Buttons';
import EnrollmentStatusEditor from './EnrollmentStatusEditor';

import { Space } from 'antd';
import Restricted from '../../auth/Restricted';
import { IOptionItem } from '../../functions/option.functions';
import { useApiContext } from '../../store/ApiContext';
import { useErrorContext } from '../../store/ErrorContext';
import { KnownSettings } from '../../store/SettingsModel';
import { useFetchUserProfiles } from '../../store/practice/UserProfileFetcher';
import ApiErrorDisplay from '../ApiErrorDisplay';
import ColumnFilter from '../shared/AntComponents/Filter/ColumnFilter';
import { IFilteredInfo } from '../shared/AntComponents/Filter/FilteredInfo';
import MultiSelectColumnFilter from '../shared/AntComponents/Filter/MultiSelectColumnFilter';
import { SearchResultsTableWithFilters } from '../shared/AntComponents/Table/SearchResultsTableWithFilters';
import { antSortOptions } from '../shared/AntComponents/Table/table.functions';
import EnrollmentStatusFileUploadDragger from './EnrollmentStatusFileUploadDragger';


const PATIENT_ID_TITLE = 'Patient Id';
const PROGRAM_NAME_TITLE = 'Program'

type searchFilters = 'applicationId' | 'practiceId' | 'programId' | 'practiceName' | 'trackingId' | 'serviceStatusId' | 'patientName' | 'programName' | 'submittedBy' | 'patientId';
const defaultSearchFilter: Record<searchFilters, IFilteredInfo> = {
   applicationId: undefined,
   programId: undefined,
   patientName: undefined,
   practiceId: undefined,
   practiceName: undefined,
   trackingId: undefined,
   patientId: undefined,
   serviceStatusId: {
      title: 'Service Status',
      values: [{ label: 'Pending', value: 1 }]
   } as IFilteredInfo,
   programName: undefined,
   submittedBy: undefined
}
const initSearchFilter: EnrollmentStatusSearchRequest = {
   page: 1,
   pageSize: 25,
   sortDirection: 'desc',
   sortField: 'submittedOn',
   serviceStatusIds: [1]
}

const _keysLike: string[] = [enrollmentStatusSearchUrl];

export const EnrollmentStatusList: React.FunctionComponent = () => {
   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [filteredInfo, setFilteredInfo] = React.useState<Record<searchFilters, IFilteredInfo>>(defaultSearchFilter);
   const { availableProgramOptions } = useAvailableProgramOptions(httpGet);
   const { cmd } = useParams<{ cmd: string }>();
   const [excludePracticeIds] = React.useState<boolean>((cmd && cmd === 'dev') ? false : true);
   const [searchFilter, setSearchFilter] = React.useState<EnrollmentStatusSearchRequest>({ ...initSearchFilter, excludePracticeIds });
   const { pagedResult, isLoading } = useEnrollmentStatusSearch(httpPost, searchFilter);
   const { userProfiles } = useFetchUserProfiles(httpGet);
   const { patientIds } = useFetchEnrollmentStatusPatientIds(httpGet, excludePracticeIds);

   const [editModalOpen, setEditModalOpen] = useState(false);
   const [uploadModalOpen, setUploadModalOpen] = useState(false);
   const [editEnrollmentApplicationId, setEditEnrollmentApplicationId] = useState<number>();
   const [uploadApp, setUploadApp] = useState<EnrollmentUpload>(null);

   const handleOnChange: TableProps<EnrollmentStatusViewModel>['onChange'] = (pagination, filters, sorter, extra) => {

      const newSearchFilter = {
         ...searchFilter,
         trackingId: filteredInfo?.trackingId?.value as string,
         serviceStatusIds: filteredInfo?.serviceStatusId?.values.map(y => y.value as number),
         applicationId: filteredInfo?.applicationId?.value as string,
         assistanceProgramIds: filteredInfo?.programId?.values.map(y => y.value as number),
         patientName: filteredInfo?.patientName?.value as string,
         patientIds: filteredInfo?.patientId?.values.map(y => y.value as string),
         practiceId: filteredInfo?.practiceId?.value as string,
         practiceName: filteredInfo?.practiceName?.value as string,
         submittedBy: filteredInfo?.submittedBy?.values.map(y => y.value as number),
         page: pagination?.current
      } as EnrollmentStatusSearchRequest;
      const theSorter = Array.isArray(sorter) ? sorter[0] as SorterResult<EnrollmentStatusViewModel> : sorter as SorterResult<EnrollmentStatusViewModel>;
      if (sorter) {
         if (sorter.hasOwnProperty('field')) {
            newSearchFilter.sortField = theSorter.field as string;
            newSearchFilter.sortDirection = theSorter.order?.indexOf('desc') > -1 ? 'desc' : 'asc';
         }
      }
      setSearchFilter(newSearchFilter);
   }

   useEffect(() => {
      removeErrors({ keysLike: _keysLike });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []) // remove Errors on initial render

   useEffect(() => {
      const buildUpdatedState = (current: EnrollmentStatusSearchRequest): EnrollmentStatusSearchRequest => {
         return {
            ...current,
            trackingId: filteredInfo?.trackingId?.value as string,
            serviceStatusIds: filteredInfo?.serviceStatusId?.values.map(y => y.value as number),
            applicationId: filteredInfo?.applicationId?.value as string,
            assistanceProgramIds: filteredInfo?.programId?.values.map(y => y.value as number),
            patientName: filteredInfo?.patientName?.value as string,
            patientIds: filteredInfo?.patientId?.values.map(y => y.value as string),
            practiceId: filteredInfo?.practiceId?.value as string,
            practiceName: filteredInfo?.practiceName?.value as string,
            submittedBy: filteredInfo?.submittedBy?.values.map(y => y.value as number),
            // always reset the page when the filters change to ensure we're seeing any results that come back
            page: 1
         };
      }

      setSearchFilter((s) => buildUpdatedState(s));
   }, [filteredInfo, excludePracticeIds])

   const submittedByOptions = React.useMemo(() => {
      return userProfiles?.map((profile): IOptionItem => ({
         label: `${profile.firstName} ${profile.lastName}`,
         value: profile.userProfileId
      } as IOptionItem));
   }, [userProfiles]);

   const patientIdOptions = React.useMemo(() => {
      return patientIds?.map((patientId): IOptionItem => ({
         label: patientId,
         value: patientId,
         key: patientId
      } as IOptionItem))
   }, [patientIds])

   const editApplicationModal = (applicationId: number) => {
      setEditModalOpen(true);
      setEditEnrollmentApplicationId(applicationId);
   }

   const closeEditModal = () => {
      setEditModalOpen(false);
      setEditEnrollmentApplicationId(undefined);
   }


   const uploadApplicationModal = (app: EnrollmentStatusSearchResult) => {
      setUploadModalOpen(true);
      setUploadApp({ applicationId: app.applicationId, enrollment: null, file: null, fileName: '', fileDescription: '' })
   }

   const closeUploadModal = () => {
      setUploadModalOpen(false);
      setUploadApp(null);
   }

   const enrollmentStatusColumns: ColumnsType<EnrollmentStatusSearchResult> = [
      {
         title: <ColumnFilter title='Application Id'
            filteredInfo={filteredInfo?.applicationId}
            content={<BasicInputField
               label='Search Application Id:'
               placeholder='Search by Application Id'
               type="text"
               name="applicationIdSearch"
               value={filteredInfo.applicationId?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     applicationId: {
                        title: 'Application Id',
                        value: e as string
                     }
                  })
               }}
            />}
         />,
         dataIndex: 'applicationId',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={filteredInfo.applicationId?.value as string} targetString={record.applicationId.toString()} />
      },
      {
         title: <ColumnFilter title={PROGRAM_NAME_TITLE}
            filteredInfo={filteredInfo.programName}
            content={<MultiSelectColumnFilter
               options={availableProgramOptions}
               searchPlaceholder={`Filter by ${PROGRAM_NAME_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'programId'}
               filteredInfoTitle={PROGRAM_NAME_TITLE} />}
         />,
         dataIndex: 'programName',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => formatApplicationProgramName(record.assistanceProgramId, record.programName)
      },
      {
         title: 'Application Service Name(s)',
         dataIndex: 'assistanceServiceNames',
         width: '25rem',
         sorter: true,
         sortDirections: antSortOptions,
      },
      {
         title: 'Drug',
         dataIndex: 'brandName',
         sorter: true, // sorting on this composite field works because its treated as its own field in our db query
         sortDirections: antSortOptions,
      },
      {
         title: <ColumnFilter title='Pt Name'
            filteredInfo={filteredInfo.patientName}
            content={<BasicInputField
               label='Search Patient Name:'
               placeholder='Search by Patient Name'
               type="text"
               name="patientNameSearch"
               value={filteredInfo.patientName?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     patientName: {
                        title: 'Patient Name',
                        value: e as string
                     }
                  })
               }}
            />}
         />,
         dataIndex: 'patientName',
         //sorter: true,  TODO: sorting on composite fields...
         //sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={filteredInfo.patientName?.value as string} targetString={record.patientName} />
      },
      {
         dataIndex: 'patientId',
         title: <ColumnFilter title={PATIENT_ID_TITLE}
            filteredInfo={filteredInfo.patientId}
            content={<MultiSelectColumnFilter
               options={patientIdOptions}
               searchPlaceholder={`Filter by ${PATIENT_ID_TITLE}`}
               allowSelectAll={true}
               searchable={true}
               filteredInfo={filteredInfo}
               setFilteredInfo={setFilteredInfo}
               filteredInfoKey={'patientId'}
               filteredInfoTitle={PATIENT_ID_TITLE}
            />}
         />,
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => <HighlightSearchText searchString={filteredInfo.patientId?.value as string} targetString={record.patientId} />
      },
      {
         title: <ColumnFilter title='Practice Id'
            filteredInfo={filteredInfo.practiceId}
            content={<BasicInputField
               label='Search Practice Id:'
               placeholder='Search by Practice Id'
               type="text"
               name="practiceIdSearch"
               value={filteredInfo.practiceId?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     practiceId: {
                        title: 'Practice Id',
                        value: e as string
                     }
                  })
               }}
            />}
         />,
         dataIndex: 'practiceId',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={filteredInfo.practiceId?.value as string} targetString={record.practiceId.toString()} />
      },
      {
         title: <ColumnFilter title='Practice Name'
            filteredInfo={filteredInfo.practiceName}
            content={<BasicInputField
               label='Search Practice Name:'
               placeholder='Search by Practice Name'
               type="text"
               name="practiceNameSearch"
               value={filteredInfo.practiceName?.value as string}
               onChange={(e) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     practiceName: {
                        title: 'Practice Name',
                        value: e as string
                     }
                  })
               }}
            />}
         />,
         dataIndex: 'practiceName',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) =>
            <HighlightSearchText searchString={filteredInfo.practiceName?.value as string} targetString={record.practiceName} />
      },
      {
         title: 'Submitted On',
         dataIndex: 'submittedOn',
         sorter: true,
         sortDirections: antSortOptions,
         defaultSortOrder: 'descend',
         render: (val) => formatDateTimeString(val)
      },
      {
         title: <ColumnFilter title='Submitted By'
            filteredInfo={filteredInfo.submittedBy}
            content={<BasicDropdownField
               label='Filter by Submitted By:'
               placeholder='Filter by Submitted By'
               clearable={true}
               multiple={true}
               search={true}
               value={filteredInfo.submittedBy?.values.map(y => y.value as number)}
               onChangeOption={(val, opt) => {
                  setFilteredInfo({
                     ...filteredInfo,
                     submittedBy: {
                        title: 'Submitted By',
                        values: opt as IOptionItem[]
                     }
                  })
               }}
               name='submittedByFilter'
               options={submittedByOptions}
            />}
         />,
         dataIndex: '',
         //sorter: true,  TODO: sorting on composite fields... 
         //sortDirections: antSortOptions,
         render: (text, record) => `${record.submittedFirstName} ${record.submittedLastName}`
      },
      {
         title: 'Deleted',
         dataIndex: 'deleted',
         sorter: true,
         sortDirections: antSortOptions,
         render: (text, record) => record?.deleted ? 'Yes' : ''
      },
      {
         fixed: 'right',
         width: '8rem',
         render: (text, record) => {
            return <Restricted requiredRoles={[KnownSettings.ContentAdmin]}>
               <Space>
                  <EditButton
                     title='Edit'
                     onClick={() => editApplicationModal(record.applicationId)}
                     disabled={isLoading}
                     loading={isLoading}
                  />
                  <ActionButton
                     title='Upload'
                     onClick={() => uploadApplicationModal(record)}
                     icon={<CustomIcon type={CustomIconType.AntCloudOutlined} />}
                     disabled={isLoading}
                     loading={isLoading}
                  />
               </Space>
            </Restricted>
         }
      }
   ];

   const trackingIdTooltipContent = (
      <>
         <div>Coherus Solutions = SRID</div>
         <div>CareASSIST Patient Support Program = CaseID</div>
      </>
   );

   const html = (
      <>
         <ApiErrorDisplay
            title={'Error'}
            keysLike={_keysLike}
         />

         <SearchResultsTableWithFilters
            rowkey={'applicationId'}
            columns={enrollmentStatusColumns}
            data={pagedResult?.data}
            serverSideRowCount={pagedResult?.totalRecords}
            currentPage={searchFilter.page}
            currentPageSize={searchFilter.pageSize}
            setCurrentPageSize={newPageSize => {
               setSearchFilter({
                  ...searchFilter,
                  page: 1, //reset the page to keep us on the page
                  pageSize: Number(newPageSize)
               })
            }}
            onChange={handleOnChange}
            titleText='Enrollment Status'
            fixedHeader={true}
            filteredInfo={filteredInfo}
            setFilteredInfo={setFilteredInfo}
            onFiltersClear={() => setFilteredInfo(defaultSearchFilter)}
            scrollX="150rem"
            scrollY={'calc(100vh - 350px)'}
            additionalComponents={[
               <BasicInputField
                  tooltip={trackingIdTooltipContent}
                  label='Search Tracking Id:'
                  placeholder='Search by Tracking Id'
                  type="text"
                  name="trackingIdSearch"
                  value={filteredInfo.trackingId?.value as string}
                  onChange={(e) => {
                     setFilteredInfo({
                        ...filteredInfo,
                        trackingId: {
                           title: 'Tracking Id',
                           value: e as string
                        }
                     })
                  }}
               />,
               <BasicDropdownField
                  label='Filter by Service Status:'
                  placeholder='Filter by Service Status'
                  value={filteredInfo.serviceStatusId?.values.map(y => y.value as number)}
                  search={true}
                  multiple={true}
                  onChangeOption={(val, opt) => {
                     setFilteredInfo({
                        ...filteredInfo,
                        serviceStatusId: {
                           title: 'Service Status',
                           values: opt as IOptionItem[]
                        }
                     })
                  }}
                  name='serviceStatusFilter'
                  clearable
                  options={EnrollmentStatusServiceStatusOptions} />,

            ]}
         />

         {editModalOpen &&
            <EnrollmentStatusEditor
               editModalOpen={editModalOpen}
               editEnrollmentApplicationId={editEnrollmentApplicationId}
               closeEditor={closeEditModal}
            />
         }

         {uploadModalOpen && <>
            <EnrollmentStatusFileUploadDragger
               isEditorOpen={uploadModalOpen}
               onCloseEditor={closeUploadModal}
               applicationId={uploadApp.applicationId}
            />
         </>}
      </>
   );

   return html;
}
export default EnrollmentStatusList;