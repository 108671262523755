import * as React from 'react';
import { Image } from 'antd';

interface IProps {
    iconName?: string;
    imageSrc?: string;
    className?: string;
    styles?: React.CSSProperties;
    title?: string;
}

const ImageContainer: React.FC<IProps> = (props) => {
   const { iconName, imageSrc, className, styles, title } = props;

    // why aren't we using Ant's icons that come baked-in? ╭( ๐_๐)╮
    const fontawesomeSrc = `https://apcdn.assistpoint.com/cdn-images/practice/shared/fontawesome/${props.iconName}.svg`

   return <Image
      title={title}
      src={imageSrc || fontawesomeSrc}
      style={styles || { 'width': 25 }}
      className={className}
      preview={false}
      data-image={`${iconName ? iconName : ''}imagecontainer`} />
}

export default ImageContainer;
