import { IOptionItem } from "../../functions/option.functions";

export const ServiceCategoryType = {
   CoPay: 1,
   FreeDrug: 2,
   ReplacementDrug: 3,
   Other_PharmacyTriage: 6,
   Other_Grocery_Food: 8,
   Other_Household_Bill: 9,
   Other_Insurance_Premiums: 10,
   Other_Practice_Balances: 11,
   Other_Misc: 12,
   Other_Physical_Therapy: 13,
   Other_Travel: 14,
   Other_Wigs: 15,
   Other_Limited_Supply: 16,
   Other_Diagnostic_Testing: 17,
   Other_Education: 18,
   Admin_Reimb_Support: 20,
   Admin_Referral_To: 21,
   CoPayNational: 22,
}

export const ApplicationStatuses = [
   { key: 0, value: 0, text: 'Draft' },
   { key: 1, value: 1, text: 'Authorization Required Both' },
   { key: 2, value: 2, text: 'Authorization Required Physician' },
   { key: 3, value: 3, text: 'Authorization Required Patient' },
   { key: 4, value: 4, text: 'Authorization Requested Both' },
   { key: 5, value: 5, text: 'Authorization Requested Physician' },
   { key: 6, value: 6, text: 'Authorization Requested Patient' },
   { key: 7, value: 7, text: 'Review Ready' },
   { key: 8, value: 8, text: 'Review Failed' },
   { key: 9, value: 9, text: 'Submitted Sending' },
   { key: 10, value: 10, text: 'Submitted Open' },
   { key: 11, value: 11, text: 'Submitted Closed' },
   { key: 12, value: 12, text: 'Abandoned' },
];

export const ApplicationStatusOptions: IOptionItem[] = ApplicationStatuses.map(status => (
   {
      key: status.key,
      label: status.text,
      value: status.value
   } as IOptionItem
));

export enum ApplicationServiceStatusType {
   Selected = 0,
   Pending = 1,
   Approved = 2,
   Denied = 3,
   Discarded = 4,
   Complete = 5,
   Archived = 6,
   Error = 7,
   PendingSignatures = 8
}

const Enrollment_ValidServiceStatuses = [
   ApplicationServiceStatusType.Selected,
   ApplicationServiceStatusType.Pending,
   ApplicationServiceStatusType.Approved,
   ApplicationServiceStatusType.Denied,
   ApplicationServiceStatusType.Discarded,
   ApplicationServiceStatusType.Archived,
];

export const EnrollmentStatusServiceStatusOptions: IOptionItem[] = Enrollment_ValidServiceStatuses.map(status => {
   return {
      key: status,
      label: ApplicationServiceStatusType[status],
      value: status
   } as IOptionItem
});

// If you find yourself concerned with these, be aware that you should also be concerned with EnrollmentStatusService.cs's constants that mirror these
export const Coherus_Complete_271: number = 271;
export const Coherus_Complete_CoPay_272: number = 272;


export const EnrollmentStatus = {
   Active: 1,
   Expired: 2,
   Exhausted: 3,
   Closed: 4,
}