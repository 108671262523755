import { Col, Divider, Row, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { dateComparer, numberComparer } from '../../functions/comparer.functions';
import { formatDateTimeString, formatShortDateString } from '../../functions/format.functions';
import { IOptionItem } from '../../functions/option.functions';
import { useApiContext } from '../../store/ApiContext';
import {
   enrollmentStatusBaseUrl,
   saveEnrollmentStatus,
   useFetchAssistanceServices,
   useFetchSingleEnrollmentStatus
} from '../../store/content-tool/EnrollmentStatusFetcher';
import {
   ApplicationService,   
   Enrollment,
   EnrollmentStatus,
   EnrollmentStatusViewModel,
   formatApplicationStatus,
   formatCreatedName,
   formatDeleted,
   formatDeletedName,
   formatServiceEnrollmentIds,
   formatSubmittedName,
} from '../../store/content-tool/EnrollmentStatusModel';

import {
   Coherus_Complete_271, Coherus_Complete_CoPay_272,
   ServiceCategoryType, EnrollmentStatusServiceStatusOptions,
   ApplicationServiceStatusType
} from '../../store/content-tool/EnrollmentStatusConstants';
import { useErrorContext } from '../../store/ErrorContext';
import { interactionBaseUrl, saveInteraction, useInteractionList } from '../../store/practice/InteractionFetcher';
import { ADMIN_PORTAL_INTERACTION_TYPE, Interaction } from '../../store/practice/InteractionModel';
import { useFetchBrandDefaults } from '../../store/program/AssistanceServiceBrandDefaultsFetcher';
import { useFetchBenefitPeriodType } from '../../store/program/BenefitPeriodTypeFetcher';
import { monthOptions } from '../../store/program/BenefitPeriodTypeModel';
import ApiErrorDisplay from '../ApiErrorDisplay';
import Dialog from '../Dialog';
import MinimalistTable from '../shared/AntComponents/Table/MinimalistTable';
import { BasicDatePickerField, BasicDropdownField, BasicFieldWrapper, BasicInputField, TValues } from '../shared/BasicInputLibrary';
import { AddButton, CancelButton, DeleteButton, SaveButton } from '../shared/Buttons';
import Spinner from '../Spinner';
import { SmallErrorMessage } from '../shared/AntComponents/Typography/SmallMessages';
const { Title } = Typography;


interface IEnrollmentError {
   applicationServiceId: number,
   eIndex: number,
   appSvcIndex: number,
   propertyName?: string,
   message?: string
}

interface IProps {
   editModalOpen: boolean,
   editEnrollmentApplicationId: number,
   closeEditor: () => void
}

const _keysLike: string[] = [enrollmentStatusBaseUrl, interactionBaseUrl];

const EnrollmentStatusEditor: React.FC<IProps> = (props) => {
   const { editModalOpen, editEnrollmentApplicationId, closeEditor } = props;

   const { httpGet, httpPost } = useApiContext();
   const { removeErrors } = useErrorContext();
   const [isFormSaving, setIsFormSaving] = React.useState<boolean>(false);

   const { benefitPeriodTypes } = useFetchBenefitPeriodType(httpGet);
   const { availableServices } = useFetchAssistanceServices(httpGet);
   const { interactionList: interactionListFromStore } = useInteractionList(httpGet);
   const { brandDefaults } = useFetchBrandDefaults(httpGet);
   const { enrollmentStatus, isLoading, error } = useFetchSingleEnrollmentStatus(httpGet, editEnrollmentApplicationId);

   const [changedToggle, setChangedToggle] = useState<boolean>(true); // Used to force rerender of edit modal is any value updated
   const [enrollmentErrors, setEnrollmentErrors] = React.useState<IEnrollmentError[]>([])
   const [editApp, setEditApp] = useState<EnrollmentStatusViewModel>(undefined);
   const [addNewInteraction, setAddNewInteraction] = useState<boolean>(false);
   const [newInteractionContent, setNewInteractionContent] = useState<string>(undefined);

   const dialogContentStyle = {
      height: '580px',
      maxHeight: '95vh',
   }

   useEffect(() => {
      isFormValid();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [changedToggle]); // Only used to rerender edit modal when values changed

   useEffect(() => {

      // AH-2428
      const checkAndApplyBrandDefaults = (enrollmentStatus: EnrollmentStatus) => {
         if (!brandDefaults) return;

         enrollmentStatus.applicationServices.forEach((service) => {
            service.enrollments.forEach((enrollment) => {
               const brandDefault = brandDefaults.find((brandDefault) => brandDefault.brandId == enrollmentStatus.brandId
                  && brandDefault.assistanceServiceId == service.assistanceServiceId);
               if (!brandDefault) return;

               if (enrollment.backDate === undefined) enrollment.backDate = brandDefault.backDateDays;
               if (enrollment.timelyFiling === undefined) enrollment.timelyFiling = brandDefault.timelyFilingDays;
               if (enrollment.guaranteedAmount === undefined) enrollment.guaranteedAmount = brandDefault.guaranteedAmount;
               if (enrollment.patientCopay === undefined) enrollment.patientCopay = brandDefault.copayAmountDose1;
               if (enrollment.PatientCopayDose1 === undefined) enrollment.PatientCopayDose1 = brandDefault.copayAmountDoseN;
            })
         })
      }

      if (enrollmentStatus) {
         const copiedItemToEdit: EnrollmentStatusViewModel = JSON.parse(JSON.stringify(enrollmentStatus)); // Deep Copy of object so any edits are thrown out if cancelled instead of saved
         checkAndApplyBrandDefaults(copiedItemToEdit);
         setEditApp(copiedItemToEdit);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [enrollmentStatus, editEnrollmentApplicationId, interactionListFromStore, brandDefaults]);

   const interactionList = React.useMemo(() => {
      if (editApp && interactionListFromStore?.length > 0) {
         return interactionListFromStore?.filter(l => l.applicationId === editApp.applicationId)
      }
   }, [interactionListFromStore, editApp])


   const getAssistanceProgramName = (assistanceServiceId: number): string => {
      const program = availableServices.find(s => s.assistanceServiceId === assistanceServiceId);
      return program?.programName;
   }

   const getNewServicesOptions = (assistanceProgramId: number): IOptionItem[] => {
      const options: IOptionItem[] = [];
      let d: IOptionItem = {
         key: -1,
         label: '',
         disabled: true,
         content: <Divider style={{ borderColor: 'black', margin: '0px' }} />
      } as IOptionItem;

      const selectedServices = availableServices?.filter(s => s.assistanceProgramId === editApp?.assistanceProgramId);

      selectedServices.forEach(s => {
         if (!options.some(y => y.value === s.assistanceServiceId)) {
            const opt = { value: s.assistanceServiceId, label: `[${s.assistanceServiceId}] ${s.assistanceServiceName}` };
            options.push(opt);
         }
      });

      if (assistanceProgramId === Coherus_Complete_271) {
         options.push(d);

         const fdServices: IOptionItem[] = availableServices?.filter(s => s.assistanceProgramId === Coherus_Complete_CoPay_272).map(s => {
            return { key: `${s.assistanceProgramId}_${s.assistanceServiceId}`, value: s.assistanceServiceId, label: `${s.programName}: ${s.assistanceServiceName}` };
         });
         options.push(...fdServices);
      }

      return options;
   }

   const removeService = (index: number) => {
      editApp.applicationServices.splice(index, 1);
      setChangedToggle(!changedToggle);
   }

   const generateStatusOptions = (serviceCategoryId: number, isNew: boolean) => {
      return EnrollmentStatusServiceStatusOptions.map(s => {
         if (s.value === ApplicationServiceStatusType.Selected || s.value === ApplicationServiceStatusType.Archived) { return { ...s, disabled: true }; }
         if (s.value === 1) {
            return isNew ? { ...s } : { ...s, disabled: true };
         }
         if (s.value === ApplicationServiceStatusType.Approved && serviceCategoryId === ServiceCategoryType.Admin_Reimb_Support) { return { ...s, label: 'Completed' }; }
         if (s.value === ApplicationServiceStatusType.Denied && serviceCategoryId === ServiceCategoryType.Admin_Reimb_Support) { return { ...s, disabled: true }; }
         return { ...s };
      });
   }

   const updateObjectProp = (obj: any, prop: string, value: string | number | Date) => {
      if (obj.serviceCategoryId && obj.serviceCategoryId !== ServiceCategoryType.Admin_Reimb_Support &&
         prop === 'serviceStatus' && value === ApplicationServiceStatusType.Approved) {
         obj.enrollments.forEach((e: Enrollment) => {
            e.status = ApplicationServiceStatusType.Pending;
         });
      }

      obj[prop] = value;
      setChangedToggle(!changedToggle);
   }

   const handleEnrollmentFieldChange = (enrollment: Enrollment, eIndex: number, appSvcIndex: number, propertyName: string, value?: string | number | Date): boolean => {
      let isValid = true;
      let err = validateEnrollmentField(enrollment, eIndex, appSvcIndex, propertyName, value);

      const enrollmentErrors_copy = [...enrollmentErrors];

      const idx_err = enrollmentErrors_copy.findIndex(y =>
         y.applicationServiceId === enrollment.applicationServiceId &&
         y.propertyName === propertyName &&
         y.appSvcIndex === appSvcIndex &&
         y.eIndex === eIndex);

      if (idx_err > -1) {
         enrollmentErrors_copy.splice(idx_err, 1);
      }

      if (err) {
         isValid = false;
         enrollmentErrors_copy.push(err);
      }
      setEnrollmentErrors([...enrollmentErrors_copy]);

      return isValid
   }

   //this method has to return the IEnrollmentError so the caller can call setEnrollmentErrors.  Attempting to do this
   //in the isFormValid caused each save to overrite the previous. some hook quirkiness.
   const validateEnrollmentField = (enrollment: Enrollment, eIndex: number, appSvcIndex: number, propertyName: string, value?: string | number | Date): IEnrollmentError => {

      if (editApp.applicationServices[appSvcIndex]?.serviceStatus === ApplicationServiceStatusType.Approved && enrollment.trackingId) {
         switch (propertyName) {
            case "startDate":
               if (!value) {
                  return {
                     applicationServiceId: enrollment.applicationServiceId,
                     eIndex: eIndex,
                     appSvcIndex: appSvcIndex,
                     propertyName: 'startDate',
                     message: 'Start Date is required'
                  };
               }
               break;
            case "endDate":
               if (!value) {
                  return {
                     applicationServiceId: enrollment.applicationServiceId,
                     eIndex: eIndex,
                     appSvcIndex: appSvcIndex,
                     propertyName: 'endDate',
                     message: 'End Date is required'
                  };
               }
               break;
            case "approvedAmount":
               if (!value && value !== 0) {
                  return {
                     applicationServiceId: enrollment.applicationServiceId,
                     eIndex: eIndex,
                     appSvcIndex: appSvcIndex,
                     propertyName: 'approvedAmount',
                     message: 'Approved Amount is required'
                  };
               }
               break;
            case "backDate":
               if (!value && value !== 0) {
                  return {
                     applicationServiceId: enrollment.applicationServiceId,
                     eIndex: eIndex,
                     appSvcIndex: appSvcIndex,
                     propertyName: 'backDate',
                     message: 'Back Date is required'
                  }
               }
               break;
         };
      }

      return null;
   }

   const getEnrollmentFieldError = (enrollment: Enrollment, eIndex: number, appSvcIndex: number, propertyName: string): string => {
      if (enrollmentErrors.length > 0) {
         const error = enrollmentErrors.find(y => y.applicationServiceId === enrollment.applicationServiceId &&
            y.propertyName === propertyName &&
            y.eIndex === eIndex &&
            y.appSvcIndex === appSvcIndex);
         if (error) {
            return error.message;
         }
      }
      return null;
   }

   const getErrorDisplay = (enrollment: Enrollment, eIndex: number, appSvcIndex: number, propertyName: string) => {
      const errMsg = getEnrollmentFieldError(enrollment, eIndex, appSvcIndex, propertyName);

      if (errMsg) 
         return <SmallErrorMessage fontWeight='semibold' textColor='errorDefault' dataAttr='awaitingSignatureMessage'>{errMsg}</SmallErrorMessage>
      return;
   }

   const isFormValid = (): boolean => {

      const enrollmentErrors_copy = [...enrollmentErrors];

      if (!editApp) return;

      editApp.applicationServices?.map((appSvc, appSvcIdx) => {
         if (appSvc.serviceCategoryId !== ServiceCategoryType.Admin_Reimb_Support) {
            appSvc.enrollments?.map((enrollment, eIdx) => {
               let startDateErr = validateEnrollmentField(enrollment, eIdx, appSvcIdx, 'startDate', enrollment.startDate);
               const idx_startDateErr = enrollmentErrors_copy.findIndex(y => y.applicationServiceId === enrollment.applicationServiceId && y.propertyName === 'startDate' && y.eIndex === eIdx);
               if (idx_startDateErr > -1) {
                  enrollmentErrors_copy.splice(idx_startDateErr, 1);
               }

               if (startDateErr) {
                  enrollmentErrors_copy.push(startDateErr);
               }


               let endDateErr = validateEnrollmentField(enrollment, eIdx, appSvcIdx, 'endDate', enrollment.endDate);
               const idx_endDateErr = enrollmentErrors_copy.findIndex(y => y.applicationServiceId === enrollment.applicationServiceId && y.propertyName === 'endDate' && y.eIndex === eIdx);
               if (idx_endDateErr > -1) {
                  enrollmentErrors_copy.splice(idx_endDateErr, 1);
               }

               if (endDateErr) {
                  enrollmentErrors_copy.push(endDateErr);
               }

               let approvedAmountErr = validateEnrollmentField(enrollment, eIdx, appSvcIdx, 'approvedAmount', enrollment.approvedAmount);
               const idx_approvedAmountErr = enrollmentErrors_copy.findIndex(y => y.applicationServiceId === enrollment.applicationServiceId && y.propertyName === 'approvedAmount' && y.eIndex === eIdx);
               if (idx_approvedAmountErr > -1) {
                  enrollmentErrors_copy.splice(idx_approvedAmountErr, 1);
               }

               if (approvedAmountErr) {
                  enrollmentErrors_copy.push(approvedAmountErr);
               }

               let backDateErr = validateEnrollmentField(enrollment, eIdx, appSvcIdx, 'backDate', enrollment.backDate);
               const idx_backDateErr = enrollmentErrors_copy.findIndex(y => y.applicationServiceId === enrollment.applicationServiceId && y.propertyName === 'backDate' && y.eIndex === eIdx);
               if (idx_backDateErr > -1) {
                  enrollmentErrors_copy.splice(idx_backDateErr, 1);
               }

               if (backDateErr) {
                  enrollmentErrors_copy.push(backDateErr);
               }

            });
         }
      });

      //console.log(enrollmentErrors_copy);
      setEnrollmentErrors([...enrollmentErrors_copy]);
      return enrollmentErrors_copy.length === 0;
   };

   const addService = (serviceId: number) => {
      const service = availableServices?.find(s => s.assistanceServiceId === serviceId);

      const newService: ApplicationService = {
         applicationServiceId: -1,
         //if Coherus_Complete_CoPay_272 -> then we create a new application
         applicationId: service.assistanceProgramId === Coherus_Complete_CoPay_272 ? -1 : editApp.applicationId,
         assistanceServiceId: service.assistanceServiceId,
         assistanceServiceName: service.assistanceServiceName,
         applicationServiceType: service.assistanceType,
         serviceStatus: 1, // Default to pending
         serviceCategory: service.serviceCategory,
         serviceCategoryId: service.serviceCategoryId,
         enrollments: [
            {
               enrollmentId: -1,
               applicationServiceId: -1,
               approvedAmount: service.approvedAwardAmount,
               trackingId: undefined,
               benefitPeriodTypeId: service.benefitPeriodTypeId,
               benefitPeriodStartDay: service.benefitPeriodStartDay,
               benefitPeriodStartMonth: service.benefitPeriodStartMonth
            },
         ]
      }

      editApp.applicationServices.push(newService);
      setChangedToggle(!changedToggle);
   }

   const closeEditModal = () => {
      setIsFormSaving(false);
      setEditApp(undefined);
      setAddNewInteraction(false);
      setNewInteractionContent(undefined);
      setEnrollmentErrors([]);
      removeErrors({ keysLike: _keysLike })
      closeEditor();
   }

   const saveEditModal = async () => {
      let anyErrors = false;
      setIsFormSaving(true);

      if (isFormValid()) {
         if (editApp.applicationServices.every(s => s.serviceStatus === ApplicationServiceStatusType.Approved || s.serviceStatus === ApplicationServiceStatusType.Denied || s.serviceStatus === ApplicationServiceStatusType.Discarded)) {
            var d = new Date();
            var year = d.getFullYear();
            var month = d.getMonth();
            var day = d.getDate();
            var c = new Date(year + 200, month, day);
            editApp.followUp = c;
         }

         await saveEnrollmentStatus(httpPost, editApp)
            .catch((err) => {
               anyErrors = true
               console.error('Error Saving Enrollment Status', err)
            });

         if (addNewInteraction) {
            let newInteraction: Interaction = {
               id: undefined, // will be re-assigned on save
               practiceId: editApp?.practiceId,
               interactionType: ADMIN_PORTAL_INTERACTION_TYPE,
               content: newInteractionContent,
               title: 'App Status Note', // Coincides with our InteractionType so if that ever changes we'll likely need to change this
               applicationId: editApp?.applicationId,
               createdBy: undefined, // will be re-assigned by server on save
               createdOn: undefined // will be re-assigned by server on save
            }
            await saveInteraction(httpPost, newInteraction)
               .catch((err) => {
                  anyErrors = true
                  console.error('Error Saving Interaction', err)
               });
         }

         if (!anyErrors) closeEditModal();
      }
      setIsFormSaving(false);
   }

   const interactionColumns: ColumnsType<Interaction> = [
      {
         title: 'Id',
         dataIndex: 'id',
         key: 'id',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => numberComparer(a.id, b.id),
         defaultSortOrder: 'descend'
      },
      {
         title: 'Content',
         dataIndex: 'content',
         key: 'content',
      },
      {
         title: 'Created On',
         dataIndex: 'createdDate',
         key: 'createdDate',
         sortDirections: ['ascend', 'descend'],
         sorter: (a, b) => dateComparer(a.createdOn, b.createdOn),
         render: (text, record) => formatShortDateString(record.createdOn)
      }
   ];

   const html = (
      <Dialog
         scrollingContent={true}
         title={'Edit Enrollment Status'}
         open={editModalOpen}
         size='large'
         actionButtons={
            <Space>
               <CancelButton onClick={() => { closeEditModal(); }} disabled={isFormSaving} />
               <SaveButton onClick={() => { saveEditModal(); }} disabled={enrollmentErrors?.length > 0} loading={isFormSaving} />
            </Space>
         }>
         <div style={dialogContentStyle}>
            <ApiErrorDisplay
               title='API error - Enrollment Status'
               keysLike={_keysLike}
            />

            {isLoading && <Spinner />}

            {!isLoading && editApp && <>
               <Row gutter={[32, 32]} justify="space-between" align="bottom" >
                  <Col span={6}>
                     <BasicInputField
                        name='applicationId'
                        label='Application Id'
                        type='text'
                        value={editApp?.applicationId}
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <BasicInputField
                        value={editApp ? formatApplicationStatus(editApp) : ''}
                        name='applicationStatus'
                        label='Application Status'
                        type='text'
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <BasicInputField
                        value={editApp?.assistanceProgramId}
                        name='assistanceProgramId'
                        label='Program Id'
                        type='text'
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <BasicInputField
                        value={editApp?.programName}
                        name='programName'
                        label='Program Name'
                        type='text'
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="bottom" >
                  <Col span={6}>
                     <BasicInputField
                        value={editApp?.patientId}
                        name='patientId'
                        label='Patient Id'
                        type='text'
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <BasicInputField
                        value={editApp?.patientName}
                        name='patientName'
                        label='Patient Name'
                        type='text'
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <BasicInputField
                        value={editApp?.practiceId}
                        name='practiceId'
                        label='Practice Id'
                        type='text'
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <BasicInputField
                        value={editApp?.practiceName}
                        name='practiceName'
                        label='Practice Name'
                        type='text'
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="bottom" >
                  <Col span={6}>
                     <BasicInputField
                        value={editApp ? formatDateTimeString(editApp.submittedOn) : ''}
                        name='submittedOn'
                        label='Submitted On'
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <BasicInputField
                        value={editApp ? formatSubmittedName(editApp) : ''}
                        name='submittedBy'
                        label='Submitted By'
                        type='text'
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <BasicInputField
                        value={editApp ? formatDateTimeString(editApp.createdOn) : ''}
                        name='createdOn'
                        label='Created On'
                        type='text'
                        disabled={true}
                     />
                  </Col>
                  <Col span={6}>
                     <BasicInputField
                        value={editApp ? formatCreatedName(editApp) : ''}
                        name='createdBy'
                        label='Created By'
                        type='text'
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="bottom" >
                  <Col span={8}>
                     <BasicInputField
                        value={editApp ? formatDeleted(editApp) : ''}
                        name='deleted'
                        label='Deleted'
                        type='text'
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     <BasicInputField
                        value={editApp ? formatDeletedName(editApp) : ''}
                        name='deletedBy'
                        label='Deleted By'
                        type='text'
                        disabled={true}
                     />
                  </Col>
                  <Col span={8}>
                     <BasicInputField
                        value={editApp ? formatDateTimeString(editApp.deletedOn) : ''}
                        name='deletedOn'
                        label='Deleted On'
                        type='text'
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Row gutter={[32, 32]} justify="space-between" align="bottom" >
                  <Col span={4}>
                     <BasicInputField
                        value={editApp?.brandName}
                        name='brandName'
                        label='Drug'
                        type='text'
                        disabled={true}
                     />
                  </Col>
               </Row>
               <Title level={3}>Interaction</Title>
               <div>
                  <MinimalistTable
                     rowKey={'id'}
                     size={'small'}
                     columns={interactionColumns}
                     data={interactionList}
                     showHeader={true}
                     bordered={true}
                  />
                  <br />
                  {addNewInteraction &&
                     <>
                        <BasicInputField
                           name='newInteraction'
                           label='New Interaction'
                           value={newInteractionContent}
                           onChange={(e) => setNewInteractionContent(String(e))}
                        />
                        <CancelButton
                           buttonText='Cancel Creating Interaction'
                           onClick={() => {
                              setAddNewInteraction(false);
                              setNewInteractionContent(undefined);
                           }}
                        />
                     </>

                  }
                  {!addNewInteraction &&
                     <AddButton
                        buttonText='New Interaction'
                        onClick={() => setAddNewInteraction(true)}
                     />
                  }
               </div>
               <br />
               <br />
               <Title level={3}>Application Services</Title>
               {editApp && editApp.applicationServices.map((service, appSvcIndex) => {
                  if (!service) return;

                  return (
                     <div key={appSvcIndex}>
                        <Divider />
                        {service.applicationServiceId &&
                           <BasicInputField
                              value={`${getAssistanceProgramName(service.assistanceServiceId)}`}
                              name='assistanceProgramName'
                              label='Program'
                              type='text'
                              disabled={true}
                           />
                        }
                        <Row gutter={[32, 32]} justify="space-between" align="bottom" >
                           <Col span={6}>
                              <BasicInputField
                                 value={service.assistanceServiceName}
                                 name='service.assistanceServiceName'
                                 label='Service Name'
                                 type='text'
                                 disabled={true}
                              />
                              {service.applicationServiceId === -1 &&
                                 <DeleteButton onClick={() => removeService(appSvcIndex)} />
                              }
                           </Col>
                           <Col span={6}>
                              <BasicInputField
                                 value={service.serviceCategory}
                                 name='service.serviceCategory'
                                 label='Service Category'
                                 type='text'
                                 disabled={true}
                              />
                           </Col>
                           <Col span={6}>
                              <BasicInputField
                                 value={formatServiceEnrollmentIds(service)}
                                 name='serviceEnrollmentIds'
                                 label='Enrollment Id'
                                 type='text'
                                 disabled={true}
                              />
                           </Col>
                           <Col span={6}>
                              <BasicDropdownField
                                 options={generateStatusOptions(service.serviceCategoryId, service.applicationServiceId === -1)}
                                 name='serviceStatusOptions'
                                 label='Status'
                                 value={service.serviceStatus}
                                 onChange={(e: any) => {
                                    updateObjectProp(service, 'serviceStatus', e)
                                 }}
                              />
                           </Col>
                        </Row>
                        {service && service.enrollments.map((enrollment, eIndex) => {
                           return (
                              <div key={eIndex}>
                                 <Row gutter={[32, 32]} justify="space-between" align="bottom" >
                                    <Col span={6}>
                                       <BasicInputField
                                          value={benefitPeriodTypes?.find(y => enrollment?.benefitPeriodTypeId === y?.id)?.benefitPeriodDescription}
                                          name='benefitPeriodTypeId'
                                          label='Benefit Period Type'
                                          disabled={true}
                                       />
                                    </Col>
                                    <Col span={6}>
                                       <BasicInputField
                                          value={(enrollment.benefitPeriodStartMonth === undefined ? '' :
                                             monthOptions.find(y => y.value === enrollment.benefitPeriodStartMonth)?.label as string)}
                                          name='benefitPeriodStartMonth'
                                          label='Benefit Period Start Month'
                                          disabled={true}
                                       />
                                    </Col>
                                    <Col span={6}>
                                       <BasicInputField
                                          value={enrollment.benefitPeriodStartDay}
                                          name='benefitPeriodStartDay'
                                          label='Benefit Period Start Day'
                                          disabled={true}
                                       />
                                    </Col>
                                    <Col span={6}>
                                       <BasicInputField
                                          value={enrollment.memberId}
                                          name='memberId'
                                          label='Pharmacy MemberId'
                                          disabled={true}
                                       />
                                    </Col>
                                 </Row>
                                 <Row gutter={[32, 32]} justify="space-between" align="bottom" >
                                    <Col span={6}>
                                       <BasicInputField
                                          value={enrollment.trackingId}
                                          name='enrollmentTrackingId'
                                          label='Enrollment Tracking Id'
                                          onChange={(e: any) => updateObjectProp(enrollment, 'trackingId', e)}
                                       />
                                    </Col>
                                    {service.serviceCategoryId !== ServiceCategoryType.Admin_Reimb_Support &&
                                       <>
                                          <Col span={6}>
                                             <BasicDatePickerField
                                                value={enrollment.startDate}
                                                minDate={new Date(1753, 1, 1)}
                                                maxDate={new Date(9999, 12, 31)}
                                                name='enrollmentStartDate'
                                                label='Enrollment Start Date'
                                                required={(service?.serviceStatus === ApplicationServiceStatusType.Approved && enrollment.trackingId) ? true : false}
                                                onChange={(e: any) => {
                                                   //When a User empties this field we want to make sure we don't provide an empty string for this date field or we end up with an error on the post for save since its not a date... 
                                                   updateObjectProp(enrollment, 'startDate', e ? e : undefined);
                                                   handleEnrollmentFieldChange(enrollment, eIndex, appSvcIndex, 'startDate', e)
                                                }} />
                                          {getErrorDisplay(enrollment, eIndex, appSvcIndex, 'startDate')}
                                          </Col>
                                          <Col span={6}>
                                             <BasicDatePickerField
                                                value={enrollment.endDate}
                                                minDate={new Date(1753, 1, 1)}
                                                maxDate={new Date(9999, 12, 31)}
                                                name='enrollmentEndDate'
                                                label='Enrollment End Date'
                                                required={(service?.serviceStatus === ApplicationServiceStatusType.Approved && enrollment.trackingId) ? true : false}
                                                onChange={(e: any) => {
                                                   //When a User empties this field we want to make sure we don't provide an empty string for this date field or we end up with an error on the post for save since its not a date... 
                                                   updateObjectProp(enrollment, 'endDate', e ? e : undefined);
                                                   handleEnrollmentFieldChange(enrollment, eIndex, appSvcIndex, 'endDate', e)
                                             }} />
                                          {getErrorDisplay(enrollment, eIndex, appSvcIndex, 'endDate')}
                                          </Col>
                                          <Col span={6}>
                                             {service.serviceCategoryId !== ServiceCategoryType.FreeDrug && //BU-2031
                                                <BasicInputField
                                                   value={enrollment.approvedAmount}
                                                   name='enrollmentApprovedAmount'
                                                   label='Approved Amount'
                                                   type='number'
                                                   required={(service?.serviceStatus === ApplicationServiceStatusType.Approved && enrollment.trackingId) ? true : false}
                                                   onChange={(e: any) => {
                                                      //When a User empties this field we want to make sure we don't provide an empty string for this numeric field or we end up with an error on the post for save since its not a number... 
                                                      updateObjectProp(enrollment, 'approvedAmount', e ? Number(e) : undefined);
                                                      handleEnrollmentFieldChange(enrollment, eIndex, appSvcIndex, 'approvedAmount', e)
                                                   }} />
                                          }
                                          {getErrorDisplay(enrollment, eIndex, appSvcIndex, 'approvedAmount')}
                                          </Col>

                                       </>
                                    }
                                 </Row>
                                 {/*AH-3925 \/ */}
                                 {service.serviceCategoryId !== ServiceCategoryType.Admin_Reimb_Support &&
                                    <>
                                       {service.serviceCategoryId !== ServiceCategoryType.FreeDrug && //BU-2031
                                          <Row gutter={[32, 32]} justify="space-between" align="bottom" >
                                             <Col span={6}>
                                                <BasicInputField
                                                   value={enrollment.backDate}
                                                   name='backDate'
                                                   label='Back Date'
                                                   type='number'
                                                   required={(service?.serviceCategoryId !== ServiceCategoryType.Admin_Reimb_Support
                                                      && service?.serviceStatus === ApplicationServiceStatusType.Approved
                                                      && enrollment.trackingId) ? true : false}
                                                      onChange={(e: TValues) => {
                                                      //When a User empties this field we want to make sure we don't provide an empty string for this numeric field or we end up with an error on the post for save since its not a number... 
                                                      updateObjectProp(enrollment, 'backDate', e ? Number(e) : undefined);
                                                      handleEnrollmentFieldChange(enrollment, eIndex, appSvcIndex, 'backDate', e as number)
                                                }} />
                                                {getErrorDisplay(enrollment, eIndex, appSvcIndex, 'backDate')}
                                             </Col>
                                             <Col span={6}>
                                                <BasicInputField
                                                   value={enrollment.timelyFiling}
                                                   name='enrollmentTimelyFiling'
                                                   label='Timely Filing'
                                                   type='number'
                                                   onChange={(e: any) => {
                                                      //When a User empties this field we want to make sure we don't provide an empty string for this numeric field or we end up with an error on the post for save since its not a number... 
                                                      updateObjectProp(enrollment, 'timelyFiling', e ? Number(e) : undefined);
                                                   }} />
                                             </Col>
                                             <Col span={6}>
                                                <BasicInputField
                                                   value={enrollment.guaranteedAmount}
                                                   name='enrollmentGuaranteedAmount'
                                                   label='Guaranteed Amount'
                                                   type='number'
                                                   onChange={(e: any) => {
                                                      //When a User empties this field we want to make sure we don't provide an empty string for this numeric field or we end up with an error on the post for save since its not a number... 
                                                      updateObjectProp(enrollment, 'guaranteedAmount', e ? Number(e) : undefined);
                                                   }} />
                                             </Col>
                                             <Col span={6}>
                                                <BasicInputField
                                                   value={enrollment.patientCopay}
                                                   name='enrollmentPatientCopay'
                                                   label='Patient Copay (First Dose)'
                                                   type='number'
                                                   onChange={(e: any) => {
                                                      //When a User empties this field we want to make sure we don't provide an empty string for this numeric field or we end up with an error on the post for save since its not a number... 
                                                      updateObjectProp(enrollment, 'patientCopay', e ? Number(e) : undefined);
                                                   }} />
                                             </Col>
                                          </Row>
                                       }
                                       <Row gutter={[32, 32]} justify="space-between" align="bottom" >
                                          {service.serviceCategoryId !== ServiceCategoryType.FreeDrug && //BU-2031
                                             <Col span={6}>
                                                <BasicInputField
                                                   value={enrollment.PatientCopayDose1}
                                                   name='enrollmentPatientCopayN'
                                                   label='Patient Copay (Subsequent Dose)'
                                                   type='number'
                                                   onChange={(e: any) => {
                                                      //When a User empties this field we want to make sure we don't provide an empty string for this numeric field or we end up with an error on the post for save since its not a number... 
                                                      updateObjectProp(enrollment, 'patientCopayN', e ? Number(e) : undefined);
                                                   }} />
                                             </Col>
                                          }
                                          <Col span={6}>
                                             <BasicFieldWrapper
                                                field={enrollment?.reenrollmentId ? `Yes` : `No`}
                                                disabled
                                                name='reenrollment'
                                                label='Re-Enrollment' />
                                          </Col>
                                       </Row>
                                    </>
                                 }
                              </div>
                           )
                        })}
                     </div>
                  )
               })}
               {availableServices &&
                  <Row gutter={[32, 32]}>
                     <Col span={12}>
                        <BasicDropdownField
                           name='addNewService'
                           label='Add New Service'
                           placeholder='Select a service to add'
                           options={getNewServicesOptions(editApp?.assistanceProgramId)}
                           onChange={(e) => addService(Number(e))}
                        />
                     </Col>
                  </Row>
               }
            </>}
         </div>

      </Dialog>
   );

   return html;
}

export default EnrollmentStatusEditor;
